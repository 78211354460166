import React from 'react';
import {Link} from 'gatsby';
import {Helmet} from 'react-helmet';
import {Container} from 'react-bootstrap';
import Logo from '../components/Logo';

const NotFoundPage = () => {
    return (
        <>
            <Helmet title="Nicht gefunden"/>
            <Logo title="Nicht gefunden"/>

            <div className="py-5">
                <Container>
                    <h2>Seite nicht gefunden</h2>
                    <Link to="/">Zurück zur Startseite</Link>.
                </Container>
            </div>
        </>
    )
};

export default NotFoundPage;
